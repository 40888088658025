
import { defineComponent } from 'vue';
import WordType from '../enum/word-type.enum'

export default defineComponent({
  data() {
    return {
      WordType: WordType,
    }
  },
  props: [
    'word',
  ],
  methods: {

  }
})
