
import { defineComponent } from 'vue';

export default defineComponent({
  props: [
    'label',
  ],
  methods: {
  }
})
