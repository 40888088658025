
import { defineComponent } from 'vue';

export default defineComponent({
  data() {

  },
  props: [
    'value',
    'placeholder'
  ],
  methods: {
  }
})
