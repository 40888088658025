
import { defineComponent } from 'vue';

export default defineComponent({
  data() {

  },
  props: [
    'multiselect',
    'value',
    'options'
  ],
  methods: {
    toggleOption(option: any) {
      if (this.multiselect) {
        if (this.value.includes(option.value)) {
          this.$emit('input', this.value.filter((x: any) => x !== option.value));
        } else {
          this.$emit('input', [...this.value, option.value]);
        }
      } else {
        this.$emit('input', option.value);
      }
    }
  }
})
