
import CategoryTree from '../components/CategoryTree.vue'
import HorizontalSelector from '../components/HorizontalSelector.vue'
import SearchBox from '../components/SearchBox.vue'
import Button from '../components/Button.vue'
import requestMixin from '@/mixins/request-mixin';
import { defineComponent } from 'vue';
import WordCard from '../components/WordCard.vue';
import Paginator from '../components/Paginator.vue';
import Word from '../types/word.interface';

export default defineComponent({
  name: 'Domišljijski slovarček | Brskanje',
  mixins: [
    requestMixin
  ],
  components: {
    WordCard,
    Paginator,
    CategoryTree,
    HorizontalSelector,
    SearchBox,
    Button,
  },
  data() {
    return {
      canEdit: false,
      hits: [] as Word[],
      totalHits: 0,
      languagePriority: 'sl',
      categories: [] as any[],
      languageOptions: [
        {value: 'en', label: 'Angleščina', disabled: false},
        {value: 'sl', label: 'Slovenščina', disabled: false},
        {value: undefined, label: 'Ni važno', disabled: false}
      ],
      selectedLanguage: undefined as 'en' | 'sl' | undefined,
      oldSelectedLanguage: undefined,

      paginationOptions: [
        {value: 16, label: '16'},
        {value: 32, label: '32'},
        {value: 64, label: '64'},
        {value: 128, label: '128'},
      ],

      showFilters: false,

      searchFilter: {
        search: '',
        categoryIds: [],
        meaningId: undefined,
        id: undefined,
        sourceLanguage: undefined as 'en' | 'sl' | undefined,
        page: 0,
        limit: 16
      }
    }
  },
  async created() {
    this.canEdit = !!this.getAuthToken();
    this.categories = await this.getCategories();
    console.log('got categories!', JSON.parse(JSON.stringify(this.categories)));
    this.getResults();
  },
  methods: {
    showAll() {
      // clear all the filters!
      this.searchFilter.search = '';
      this.searchFilter.page = 0;
      this.searchFilter.categoryIds = [];
      this.searchFilter.meaningId = undefined;
      this.searchFilter.id = undefined;
      this.searchFilter.sourceLanguage = undefined;

      // bam
      this.getResults();
    },
    search(search: string) {
      search = search.toLowerCase().trim();

      this.searchFilter.search = search;
      this.searchFilter.page = 0;

      this.getResults();
    },
    changePage(pageNumber: number) {
      this.searchFilter.page = pageNumber;
      this.getResults();
    },
    async getResults() {
      // if search box is empty, we do not allow the "I don't care"
      // option in the language select thingy. Instead, we default
      // to showing en->sl translations.
      if (this.searchFilter.search.length === 0) {
        if (!this.selectedLanguage) {
          this.oldSelectedLanguage = this.selectedLanguage;
          this.selectedLanguage = 'en';
          this.searchFilter.sourceLanguage = 'en';
          this.languageOptions[2].disabled = true;
        }
      } else {
        // if "i don't care" was unset without user action, it also
        // gets set back to active option without user action
        if (this.selectedLanguage !== this.oldSelectedLanguage) {
          this.selectedLanguage = this.oldSelectedLanguage;
          this.searchFilter.sourceLanguage = this.selectedLanguage;
        }
        this.languageOptions[2].disabled = false;
      }

      const words = await this.getTranslations(this.searchFilter);
      console.log("hits:", words);
      this.hits = words.words;
      this.totalHits = +words.total[0].total;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    updateSelectedLanguage($event: any) {
      this.searchFilter.sourceLanguage = $event;
      this.selectedLanguage = $event;
      this.oldSelectedLanguage = $event;
      this.searchFilter.page = 0;
      this.getResults();
    },
    updateCategories($event: any) {
      this.searchFilter.categoryIds = $event;
      this.searchFilter.page = 0;
      this.getResults();
    },
    updatePageSize($event: number) {
      this.searchFilter.limit = $event;
      this.searchFilter.page = 0;
      this.getResults();
    }
  }
})
