
import { defineComponent } from 'vue';
import MeaningCard from './MeaningCard.vue';

export default defineComponent({
  data() {
    return {
      wordLinkCopied: false,
      wordLinkCopiedTimeout: undefined,
    };
  },
  components: {
    MeaningCard
  },
  props: [
    'word',
    'languagePriority',
  ],
  methods: {
    copyWordLinkToClipboard() {
      const link = `${window.location.protocol}://${window.location.host}/beseda/?id=${this.word.id}`;
      navigator.clipboard.writeText(link);
      this.wordLinkCopied = true;
      clearTimeout(this.wordLinkCopiedTimeout);
      setTimeout(() => this.wordLinkCopied = false, 2500);
    }
  }
})
