
import { defineComponent } from 'vue';
import WordSelector from '../../components/WordSelector.vue';
import requestMixin from '../../mixins/request-mixin';

export default defineComponent({
  name: 'Translations',
  components: {
    WordSelector
  },
  mixins: [
    requestMixin
  ],
  data() {
    return {
      enWord: undefined as any,
      slWord: undefined as any,
      rfc: undefined,
      description: undefined,
      translationPriority: undefined,
    }
  },
  methods: {
    setEnglishWord(word: any) {
      console.info('change:', word);
      this.enWord = word;
    },
    setSlovenianWord(word: any) {
      console.info('change:', word);
      this.slWord = word;
    },
    async addTranslation() {
      if (!this.enWord || !this.slWord) {
        return;
      }
      await this.post(
        `/translations/`,
        {
          enWordId: this.enWord?.id,
          slWordId: this.slWord?.id,
          priority: this.translationPriority,
          rfc: this.rfc,
          description: this.translationDescription
        }
      );
    },

    logout() {

    }
  }
})
