
import { defineComponent } from 'vue';
import WordCardMini from './WordCardMini.vue';
import {categoryTree2CategoryCards} from '../utils/utils';

export default defineComponent({
  components: {
    WordCardMini
  },
  props: [
    'meaningEn',
    'meaningSl',
    'languagePriority',
  ],
  computed: {
    categoryCards(): any {
      console.log('categories:', this.meaningEn.categories)
      return categoryTree2CategoryCards(this.meaningEn.categories);
    }
  },
  methods: {

  }
})

