
import requestMixin from '@/mixins/request-mixin';
import { defineComponent } from 'vue';

export default defineComponent({
  props: [
    'language',
    'languageKey',
    'selectedWordId',
    'disableEditing'
  ],
  mixins: [
    requestMixin
  ],
  data() {
    return {
      existingWords: [] as any[],
      search: '',
      showAddWord: false,

      // this is the word we edit
      word: {
        id: null,
        word: '',
        word_f: null,
        word_m: null,
        word_plural: null,
        description: null,
        notes: null,
        rfc: null,
      },
      // this is the word as displayed
      selectedWord: {
        id: null,
        word: '',
        word_f: null,
        word_m: null,
        word_plural: null,
        description: null,
        notes: null,
        rfc: null,
      },
    }
  },
  methods: {
    selectWord(selectedWord: any) {
      this.selectedWord = selectedWord;
      this.$emit('change', this.selectedWord);

      // allow editing. Always edit copy, not the main word.
      this.word = JSON.parse(JSON.stringify(selectedWord));

      // only show word if selectedWord is not an empty object
      if (selectedWord.word) {
        this.showAddWord = true;
      } else {
        this.showAddWord = false;
      }
    },
    async searchExisting(search: string) {
      this.showAddWord = false;

      // ensure we clear selection when search terms change.
      // we must also tell that to the outside world as well.
      this.selectWord({});

      this.search = search;
      
      try {
        const res = await this.get(`/words/?s=${search}&lang=${this.languageKey}`);
        this.existingWords = res.data;
      } catch (e) {
        console.error('Fetching words failed. Error:', e);
      }
    },
    async saveWord(forceNewWord?: boolean) {
      try {
        if (forceNewWord) {
          this.word.id = null;
        }

        const res = await this.post(
          `/words/`,
          {
            ... this.word,
            lang: this.languageKey
          }
        );

        // if we edited a word, otherwise we append
        if (this.word.id) {
          const wordIndex = this.existingWords.findIndex(x => x.id === this.word.id);
          this.existingWords[wordIndex] = res.data;
          this.selectWord(res.data);
        } else {
          this.existingWords.push(res.data);
          this.selectWord(res.data);
        }

        this.showAddWord = false;
      } catch (e) {
        console.error('Error while adding or updating word:', e);
      }
    }
  }
})
