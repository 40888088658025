
import requestMixin from '@/mixins/request-mixin';
import { defineComponent } from 'vue';

export default defineComponent({
  props: [
    'languageKey',
    'word'
  ],
  mixins: [
    requestMixin
  ],
  data() {
    return {
      existingWords: [] as any[],
      search: '',
      showAddWord: false,
      selectedLanguage: undefined as boolean | undefined,

      // this is the word we edit
      // word: {
      //   id: null,
      //   word: '',
      //   word_f: null,
      //   word_m: null,
      //   word_plural: null,
      //   description: null,
      //   notes: null,
      //   rfc: null,
      // },
      // this is the word as displayed
      // selectedWord: {
      //   id: null,
      //   word: '',
      //   word_f: null,
      //   word_m: null,
      //   word_plural: null,
      //   description: null,
      //   notes: null,
      //   rfc: null,
      // },
    }
  },
  methods: {
    async saveWord(forceNewWord?: boolean) {
      try {
        if (forceNewWord) {
          this.word.id = null;
        }

        const langKey = this.languageKey ?? this.selectedLanguage;

        const res = await this.post(
          `/words/`,
          {
            ... this.word,
            lang: langKey
          }
        );
        if (res.data.error) {
          throw res.data;
        }

        // emit updated word
        this.$emit('updated', {langKey, ...res.data});
      } catch (e) {
        console.error('Error while adding or updating word:', e);
        this.$emit('error', e);
      }
    }
  }
})
