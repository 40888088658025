
import WordSelector from '@/components/WordSelector.vue';
import WordEditor from '@/components/WordEditor.vue';
import requestMixin from '@/mixins/request-mixin';
import { defineComponent } from 'vue';

/**
 * DONE & working:
 * 
 *  - adding words
 *  - adding translations
 *  - deleting translations
 *  - deleting words
 * 
 * TODO:
 *  - edit word
 *  - edit translation
 *  - auto add translation priority (requires BE)
 */

export default defineComponent({
  name: 'Domišljijski slovarček',
  components: {
    WordSelector,
    WordEditor
  },
  mixins: [
    requestMixin
  ],
  data(): {
    hits: any[],
    searchString: string,
    visibleForms: {[x: string]: boolean},
    selectedWord?: any,
    selectedTranslation?: any,
    selectedTranslationWord?: any,
    translationData: any,
    notifications: any[],
  } {
    return {
      hits: new Array<any>(),
      searchString: '',
      visibleForms: {
        addWord: false as boolean,
        editWord: false as boolean,
        addTranslation: false as boolean,
        editTranslation: false as boolean,
      },
      selectedWord: undefined,
      selectedTranslation: undefined,
      selectedTranslationWord: undefined,
      translationData: {},
      notifications: [],
    }
  },
  mounted() {
    this.tokenRefresh();
  },
  methods: {
    //#region auth stuff
    parseJwt (token: any) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    async tokenRefresh() {
      let token = this.parseJwt(window.localStorage.getItem('userToken'));

      // logged out users go to login page
      if (token.exp * 1000 < Date.now()) {
        this.$router.push('/durin');
      }
      if (token.exp * 1000 < (Date.now() - 72 * 3600000)) {
        const res = await this.get(
          `/auth/refresh/`
        );

        token = res.data;
        window.localStorage.setItem('userToken', token);

        // check again in like 5 min
        setTimeout(() => this.tokenRefresh(), 300000);
      }
    },
    //#endregion

    showAll() {
      (this.hits as any) = this.wordlist;
    },
    search(search: string) {
      search = search.toLowerCase().trim();
      this.searchString = search;
      if (search == '') {
        this.hits = [];
        return;
      }
      this.getResults(search);
    },
    refreshData() {
      this.getResults(this.searchString);
    },
    async getResults(search: string) {
      const res = await this.get(`/translate/?s=${search}&wordlistMode=1`);

      this.hits = [
        ...this.unflatten(res.data.en2si, 'en'),
        ...this.unflatten(res.data.si2en, 'sl')
      ]

      console.log("hits:", this.hits);
    },
    unflatten(data: any[], lang: 'en' | 'sl') {
      const processed: any[] = [];

      if (lang === 'en') {
        for (const d of data) {
          const existing = processed.find(x => x.id === +d.en_id);
         
          const translation = {
            id: +d.tr_id,
            translationNotes: d.tr_notes,
            translationRfc: !!(+d.tr_rfc),
            translationPriority: +d.tr_translation_priority,
            word_id: +d.sl_id,
            word: d.sl_word,
            word_m: d.sl_word_m,
            word_f: d.sl_word_f,
            word_plural: d.sl_word_plural,
            rfc: !!(+d.sl_rfc),
            description: d.sl_description,
            notes: d.sl_notes,
          };

          if (existing) {
            if (translation.id) {
              existing.translations.push(translation);
            }
          } else {
            const word = {
              langFlag: '🇬🇧',
              langKey: 'en',
              id: +d.en_id,
              word: d.en_word,
              word_m: d.en_word_m,
              word_f: d.en_word_f,
              word_plural: d.en_word_plural,
              rfc: !!(+d.en_rfc),
              description: d.en_description,
              notes: d.en_notes,
              translations: [] as any
            };
            if (translation.id) {
              word.translations.push(translation);
            }
            processed.push(word);
          }
        }
      } else {
        for (const d of data) {
          const existing = processed.find(x => x.id === d.sl_id);
          
          const translation = {
            id: +d.tr_id,
            translationNotes: d.tr_notes,
            translationRfc: !!(+d.tr_rfc),
            translationPriority: +d.tr_translation_priority,
            word_id: +d.en_id,
            word: d.en_word,
            word_m: d.en_word_m,
            word_f: d.en_word_f,
            word_plural: d.en_word_plural,
            rfc: !!(+d.en_rfc),
            description: d.en_description,
            notes: d.en_notes,
          };

          if (existing) {
            if (translation.id) {
              existing.translations.push(translation);
            }
          } else {
            const word = {
              langFlag: '🇸🇮',
              langKey: 'sl',
              id: +d.sl_id,
              word: d.sl_word,
              word_m: d.sl_word_m,
              word_f: d.sl_word_f,
              word_plural: d.sl_word_plural,
              rfc: !!(+d.sl_rfc),
              description: d.sl_description,
              notes: d.sl_notes,
              translations: [ ] as any
            }
            if (translation.id) {
              word.translations.push(translation);
            }
            processed.push(word);
          }
        }
      }

      return processed;
    },
    clear() {
      this.hits = [];
    },

    showNotification(notificationText: string, level: 'error' | 'success' | 'warning' | 'info', extras?: any) {
      const id = Date.now();

      this.notifications.push({
        level,
        text: notificationText,
        extras: extras ? JSON.stringify(extras, null, 2) : undefined,
        id
      });

      setTimeout(() => this.notifications = this.notifications.filter(x => x.id !== id), 5000);
    },

    /**
     * Opens a form and closes all others. This also clears selectedWord, selectedTranslationWord and translationData.
     */
    openForm(form: 'addWord' | 'editWord' | 'addTranslation' | 'editTranslation') {
      this.closeForms();
      this.visibleForms[form] = true;
    },

    /**
     * Closes all forms and clears their data
     */
    closeForms(preserveData = false) {
      for (const k in this.visibleForms) {
        this.visibleForms[k] = false;
      }

      // also unset some of the things
      this.selectedTranslationWord = undefined;
      this.selectedTranslation = undefined;
      this.translationData = {};
    },

    /**
     * Select current word for editing
     */
    selectWord(word: any) {
      console.info('selecting a word:', word);
      this.selectedWord = word;
    },

    //#region add word dialog
    /**
     * Triggers when word was added from the "add word" dialogue at the top of the page.
     */
    wordAdded(word: any) {
      this.hits = [word];
      this.selectWord(word);

      this.showNotification('Beseda dodana.', 'success');
    },
    wordAddedError(error: any) {
      this.showNotification('Napaka pri dodajanju besede.', 'error', error);
    },
    //#endregion
    //#region edit word
    editWord(word: any) {
      this.selectWord(word);
      this.openForm('editWord')
    },
    /**
     * Triggers when word was updated from the "add word" dialogue at the top of the page.
     */
    wordUpdated(word: any) {
      console.log('word updated!', word);

      const index = this.hits.findIndex(x => x.id === word.id);
      this.hits[index] = word;

      this.showNotification('Beseda posodobljena.', 'success');

      this.selectWord(word);
    },
    wordUpdatedError(error: any) {
      this.showNotification('Napaka pri urejanju besede.', 'error', error);
    },
    //#endregion

    //#region translation window
    /**
     * Selects a source word and opens 'add translation' dialog
     */
    addTranslationForWord(word: any) {
      this.openForm('addTranslation');
      this.selectWord(word);
    },
    
    /**
     * Sets the word that we'll use for translation.
     */
    selectTranslationWord(word: any) {
      console.log("selecting translation word:", word);
      this.selectedTranslationWord = word || {};
    },

    /**
     * Selects currently selected translation
     */
    selectTranslation(translation: any) {
      this.selectedTranslation = translation;
    },

    /**
     * Actually creates translation
     */
    async createTranslation() {
       try {
        const res = await this.post(
          `/translations/`,
          {
            enWordId: this.selectedWord.langKey === 'en' ? this.selectedWord.id : this.selectedTranslationWord.id,
            slWordId: this.selectedWord.langKey === 'en' ? this.selectedTranslationWord.id : this.selectedWord.id,
            priority: this.translationData.priority,
            rfc: !!(+this.translationData.rfc),
            notes: this.translationData.notes
          }
        );
        if (res.data.error) {
          throw res.data;
        }

        // force refresh the ugly way
        this.hits = [...this.hits];

        this.showNotification('Prevod dodan.', 'success');

        // close everything
        this.closeForms();
        this.refreshData();
      } catch (e) {
        console.error('Deleting translation failed. Error:', e);
        this.showNotification('Napaka pri dodajanju prevoda.', 'error', e);
      }
    },

    /**
     * Selects existing translation for update
     */
    editTranslation(word: any, translation: any) {
      this.openForm('editTranslation');

      this.translationData = {
        id: translation.id,
        notes: translation.translationNotes,
        rfc: !!+translation.translationRfc,
        priority: translation.translationPriority
      };

      this.selectWord(word);
      this.selectTranslation(translation);
    },

    /**
     * Actually creates translation
     */
    async updateTranslation() {
       try {
        const res = await this.post(
          `/translations/`,
          {
            id: this.translationData.id,
            priority: this.translationData.priority,
            rfc: !!+this.translationData.rfc,
            notes: this.translationData.notes
          }
        );
        if (res.data.error) {
          throw res.data;
        }

        // force refresh the ugly way
        this.hits = [...this.hits];

        // close everything
        this.closeForms();
        this.refreshData();

        this.showNotification('Prevod posodobljen.', 'success');
      } catch (e) {
        console.error('Updating translation failed. Error:', e);
        this.showNotification('Napaka pri posodabljanju prevoda.', 'error', e);
      }
    },

    /**
     * Update translation priority
     */
    async updateTranslationPriority(hitIndex: number, translationIndex: number, direction: 'up' | 'down') {
      const otherIndex = direction === 'up' ? translationIndex - 1 : translationIndex + 1;

      // we can't bump first translation forward or last translation backward
      if (otherIndex < 0 || otherIndex >= this.hits[hitIndex].translations.length) {
        return;
      }

      const currentTranslation = this.hits[hitIndex].translations[translationIndex];
      const otherTranslation = this.hits[hitIndex].translations[otherIndex];

      otherTranslation.priority = currentTranslation.priority + direction === 'up' ? 1 : -1;
      currentTranslation.priority = currentTranslation.priority + direction === 'up' ? -1 : 1;

      const res = await this.post(
        `/translations/`,
        {
          ...currentTranslation
        }
      );
      if (res.data.error) {
        throw res.data;
      }

      const res2 = await this.post(
        `/translations/`,
        {
          ...otherTranslation
        }
      );
      if (res2.data.error) {
        throw res2.data;
      }

      this.hits[hitIndex].translations[translationIndex] = otherTranslation;
      this.hits[hitIndex].translations[otherIndex] = currentTranslation;
    },

    /**
     * Deletes translation
     */
    async deleteTranslation(hitIndex: number, id: any) {
      try {
        const res = await this.delete(
          `/translations/`,
          {
            id
          }
        );
        if (res.data.error) {
          throw res.data;
        }

        // remove from the list of translations on successful delete.
        // note that a translation can appear TWICE on the current page,
        // which means we need to do up to two removals.
        const translationIndex = this.hits[hitIndex].translations.findIndex( (x: any) => x.id == id);
        if (translationIndex === -1) {
          // this shouldn't ever happen, but we wrote our backend in PHP ... not trustworthy
          return;
        }
        const removedTranslation = this.hits[hitIndex].translations.splice(translationIndex, 1)[0];

        // due to pagination, it's not certain that the other word exists. It's completely
        // possible the following produces an undefined:
        const otherWord = this.hits.find(x => x.id == removedTranslation?.word_id);
        if (otherWord) {
          const translationIndex2 = otherWord.translations.findIndex( (x: any) => x.id == id);
          if (translationIndex2 === -1) {
            // this shouldn't ever happen, but we wrote our backend in PHP ... not trustworthy
            return;
          }
          otherWord.translations.splice(translationIndex2, 1);
        }

        this.showNotification('Prevod izbrisan.', 'success');

        // force refresh the ugly way
        this.hits = [...this.hits];
      } catch (e) {
        console.error('Deleting translation failed. Error:', e);
        this.showNotification('Napaka pri brisanju prevoda.', 'error', e);
      }
    },
    //#endregion

    async deleteWord(word: any) {
      try {
        const res = await this.delete(
          `/words/`,
          {
            id: word.id,
            lang: word.langKey
          }
        );
        if (res.data.error) {
          throw res.data;
        }

        this.showNotification('Beseda izbrisana.', 'success');

        // remove from the wordlist on successful delete
        this.hits = this.hits.filter( (x: any) => x.id !== word.id);
      } catch (e) {
        console.error('Deleting word failed. Error:', e);
        this.showNotification('Napaka pri brisanju besede.', 'error', e);
      }
    }
  }
})
