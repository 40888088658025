
import { defineComponent } from 'vue';

export default defineComponent({
  props: [
    'total',
    'pageSize',
    'currentPage',
    'displayPages'
  ],
  computed: {
    numberOfPages(): any {
      return Math.ceil(this.total/this.pageSize);
    },
    hasFirstGap(): any {
      return (this.currentPage - this.displayPages) > 0;
    },
    hasLastGap(): any {
      return (this.currentPage + this.displayPages) < this.numberOfPages
    },
    visiblePageNumbers(): any {
      // we never count page 1 into this mess — generated numbers start with page 2
      const firstNumber = Math.max(2, this.currentPage - this.displayPages);

      // likewise, page numbers end with numberOfPages - 1 — last page number is always
      // drawn
      const lastNumber = Math.min(this.numberOfPages - 1, this.currentPage + this.displayPages);

      const pageNumbers = [];
      for (let i = firstNumber; i <= lastNumber; i++) {
        pageNumbers.push(i);
      }

      return pageNumbers;
    },
    currentPageNumber(): number {
      return (this.currentPage ?? 0) + 1;
    }
  },
  methods: {
    goToPage(page: number) {
      this.$emit('changePage', page);
    }
  }
})
