
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CategoryTree',
  emits: ['input'],
  props: [
    'languagePriority',
    'categories',
    'value',
    'depth',
    'selectParents'
  ],
  methods: {
    childrenUpdated($event: any, categoryId: number) {
      if (!this.selectParents) {
        return this.$emit('input', $event);
      }

      if ($event.added) {
        // only add self if self is missing from the list
        if ($event.value.indexOf(categoryId) === -1) {
          $event.value.push(categoryId);
        }
      } else {
        // if child is removed, we don't remove parent — parent selection should in
        // no circumstances be removed without user action
        // (this function is only ever used when 'selectParents' option is selected)
      }
      if (this.depth) {
        this.$emit('input', $event);
      } else {
        this.$emit('input', $event.value);
      }
    },
    selectCategory(categoryId: number) {
      let value = this.value ?? [];

      // because migration is fucked
      if (!Array.isArray(value)) {
        value = value.split(',');
        value = value.filter((x: any) => x !== '');
      }

      const valueIndex = value?.indexOf(categoryId);

      if (valueIndex === -1) {
        // adding new things
        value.push(categoryId);

        if (this.selectParents && this.depth > 0) {
          return this.$emit('input', {value, added: true});
        }
        this.$emit('input', value);
      } else {
        // remove things
        if (this.selectParents) {
          const category = this.categories.find((x: any)=> x.id === categoryId);
          this.removeChildrenFromValue(category, value);
          return this.$emit('input', {value});
        } else {
          this.value.splice(valueIndex, 1);
        }
        this.$emit('input', value);
      }
    },
    removeChildrenFromValue(category: any, value: number[]) {
      const i = value.findIndex((x: number) => x === category.id);
      if (i < 0) {
        return;
      }
      value.splice(i, 1);

      if (category.children) {
        for (const child of category.children) {
          // console.log('removing child:', JSON.parse(JSON.stringify(child)), 'with id:', child.id, 'from value array:', JSON.parse(JSON.stringify(value)));
          this.removeChildrenFromValue(child, value);
        }
      }
    }
  }
})
